import React from 'react';
import { graphql, navigate, withPrefix } from 'gatsby'
import { getUserLangKey } from 'ptz-i18n';
import {useCookies} from 'react-cookie';

function RedirectIndex(args) {

  const [cookies, setCookie] = useCookies(['locale']);

  // Skip build, Browsers only
  if (typeof window !== 'undefined') {

    const { langs, defaultLangKey } = args.data.site.siteMetadata.languages;
    let langKey = "en";

    if (!!cookies.locale && langs.includes(cookies.locale)) {
      langKey = cookies.locale;
    }
    else {
      langKey = getUserLangKey(langs, defaultLangKey);
    }

    setCookie('locale', langKey,
      {
        path: '/',
        secure: true,
        domain: args.data.site.siteMetadata.domain,
        maxAge: 90 * 24 * 60 * 60,
        sameSite: 'strict',
      });

    let pageUrl = withPrefix(`/${langKey}/blog`);

    if (!window.location.pathname.match(/^\/[en,ru]\/.*/)) {
      pageUrl = withPrefix(`/${langKey}${window.location.pathname}`);
    }

    navigate(pageUrl);
  };

  // It's recommended to add your SEO solution in here for bots
  // eg. https://github.com/ahimsayogajp/ahimsayoga-gatsby/blob/master/src/pages/index.js#L22
  return (<div />);
}

export default RedirectIndex;

export const pageQuery = graphql`
  query BlogQuery {
    site{
      siteMetadata{
        domain
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`;